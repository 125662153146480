import React, { useRef, useEffect, useCallback } from 'react'

import Logo from '../logo'

import styles from './style.module.scss'

import Input from '../input'

import PhoneInput from '../phone-input'

import Button from '../button'

import { useSaveMyProfile } from '../hooks'

import Form from '../form/api-form'

import { useCurrentUser } from '../protected-page'

import { ACCOUNT_TYPE_OWNER } from '../../constants'

import { navigate } from 'gatsby'

export default () => {
    const formRef = useRef({})

    const { statusCode, submit, loading, response } = useSaveMyProfile({
        formRef,
    })

    const [user] = useCurrentUser()

    useEffect(() => {
        if (statusCode === 200)
            if (user.account_type === ACCOUNT_TYPE_OWNER)
                navigate('/owner-dashboard')
            else navigate('/')
    }, [statusCode])

    const onCancelClick = useCallback(() => {
        if (user.account_type === ACCOUNT_TYPE_OWNER)
            navigate('/owner-dashboard')
        else {
            window.history.back()
        }
    }, [])

    return (
        <div className={styles.full}>
            <Logo className={styles.logo} />
            <div className={styles.formWrapper}>
                <Form
                    statusCode={statusCode}
                    ref={formRef}
                    loading={loading}
                    response={response}
                    errorsClassName={styles.errors}
                    onSubmit={() => submit()}
                >
                    <h1 className={styles.title}>Hello {user.display_name},</h1>

                    <p>This information will help us serve you better</p>

                    <Input
                        type="email"
                        label="Email"
                        name="email"
                        disabled
                        value={user.user_email}
                    />

                    <PhoneInput />

                    <Input
                        type="file"
                        required
                        label="Passport"
                        name="passport"
                    />

                    <Input
                        type="file"
                        required
                        label="Emirates ID Front"
                        name="emirates_id_front"
                    />

                    <Input
                        type="file"
                        required
                        label="Emirates ID Back"
                        name="emirates_id_back"
                    />

                    <div className={styles.footer}>
                        <Button className={styles.button}>Save</Button>
                        <Button
                            className={styles.button}
                            type="button"
                            onClick={onCancelClick}
                        >
                            Cancel
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}
