import React from 'react'

import { graphql } from 'gatsby'

import SEO from '../components/seo'

import Layout from '../components/layout'

import UserProfileForm from '../components/user-profile-form'

import { get } from 'lodash-es'

import ProtectedPage from '../components/protected-page'
import { ACCOUNT_TYPE_OWNER, ACCOUNT_TYPE_TENANT_BUYER } from '../constants'

export default ({ data }) => {
    let page = data.allWordpressPage.nodes[0]

    return (
        <Layout noFooter noSocialBar noScrollTop noHeader>
            <SEO
                title={get(page, 'yoast_meta.yoast_wpseo_title')}
                description={get(page, 'yoast_meta.yoast_wpseo_metadesc')}
            />
            <ProtectedPage
                requiredAccountType={[
                    ACCOUNT_TYPE_OWNER,
                    ACCOUNT_TYPE_TENANT_BUYER,
                ]}
            >
                <UserProfileForm />
            </ProtectedPage>
        </Layout>
    )
}

export const query = graphql`
    {
        allWordpressPage(filter: { slug: { eq: "user-profile" } }) {
            nodes {
                yoast_meta {
                    yoast_wpseo_title
                    yoast_wpseo_metadesc
                }
            }
        }
    }
`
